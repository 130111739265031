.stylepastspeker {
    border: 1px solid #f5f5f5;
    margin: 5px;
}
.stylepastspeker img {
    max-width: 100% !important;
    max-height: 150px;
    object-fit: cover;
    width: 150px;
    margin: 0px auto;
}

.agendaTable td:first-child {
    width: 200px;
    font-weight: 800;
}

.agendaTable td {
    font-family: "Lato", sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    padding: 40px 0px;
    vertical-align: top;
    border-bottom: 2px solid #39375a;
}