@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

/* Freshworks CSS */

.freshworks .inner h1,
.freshworks .inner h2,
.freshworks .inner h3,
.freshworks .inner h4,
.freshworks .inner h5,
.freshworks .inner h6 {
    font-family: 'Roboto', sans-serif;
    color: #000000;
}

.freshworks .navbar .fresh-nav .navbar-nav .nav-item .nav-link {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.freshworks .inner ul {
    margin: 0px;
    padding: 0;
}

.freshworks .inner p,
.freshworks .inner li {
    font-family: 'Roboto', sans-serif;
    color: #000000;
    font-size: 18px;
    font-weight: 400;
}

.freshworks .inner .sectionTitle {
    font-family: 'Roboto', sans-serif;
    color: #000000;
}

.freshworks .inner .aboutSection p {
    font-size: 20px;
    text-align: center;
}

.freshworks .inner .aboutSection h3 {
    font-size: 26px;
    font-weight: 700;
    margin: 25px 0px 15px;
    text-align: center;
    text-transform: uppercase;
}

.freshworks .btnFresh {
    background-image: linear-gradient(55deg, #d33c4d 0%, #ee5a23 100%);
    padding: 10px 30px;
    border: 0;
    border-radius: 6px;
    font-weight: 700;
}

.freshworks .btnFresh:hover {
    background-image: linear-gradient(55deg, #ee5a23 0%, #d33c4d 100%);
}

.freshworks .inner .whySection {
    background: #f5f5f5;
}

.freshworks .inner .whySection .whyBox {
    background: #ffffff;
    padding: 30px;
    margin-top: 30px;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
}

.freshworks .inner .whySection .whyBox:hover {
    background-image: linear-gradient(135deg, #ffaa00 0%, #8be254 100%);
    transition: all 0.3s ease-in-out;
}

.freshworks .inner .whySection .whyBox h3 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 15px;
}

.freshworks .inner .whySection .whyBox p {
    margin-bottom: 0;
}

.freshworks .inner .sectorSection {
    background: #ffffff;
}

.freshworks .inner .sectorSection .sectBox {
    position: relative;
    background: #ffffff;
    padding: 30px;
    margin-top: 30px;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    text-align: center;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.freshworks .inner .sectorSection .sectBox::before {
    content: "";
    position: absolute;
    background: #000000;
    top: -65px;
    left: -65px;
    width: 100px;
    height: 100px;
    transform: rotate(45deg);
    transition: all 0.3s ease-in-out;
}

.freshworks .inner .sectorSection .sectBox img {
    margin-bottom: 15px;
    transition: all 0.3s ease-in-out;
}

.freshworks .inner .sectorSection .sectBox h3 {
    position: relative;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0px;
    transition: all 0.3s ease-in-out;
}

.freshworks .inner .sectorSection .sectBox:hover h3 {
    color: #ffffff;
}

.freshworks .inner .sectorSection .sectBox:hover::before {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: rotate(0deg);
}

.freshworks .inner .sectorSection .sectBox:hover img {
    transform: translateY(-6px);
    filter: invert(100%);
    -webkit-filter: invert(100%);
}

.freshworks .inner .keySection {
    background: #000000;
    background-image: url(../images/freshworks/home-bg1.jpg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    background-attachment: fixed;
}

.freshworks .inner .keySection .textBox h1 {
    color: #ffffff;
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 40px;
}

.freshworks .inner .keySection .textBox p {
    color: #ffffff;
    margin-bottom: 30px;
}

.freshworks .inner .keySection .textBox ul {
    margin-top: 20px;
    padding-left: 18px;
}

.freshworks .inner .keySection .textBox ul li {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
}

.freshworks .inner .keySection .textBox ul li:last-child {
    margin-bottom: 0px;
}

.freshworks .inner .agendaSection .mainBox {
    display: flex;
    flex-wrap: wrap;
}

.freshworks .inner .agendaSection .mainBox h3 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0px;
}

.freshworks .inner .agendaSection .mainBox .boxOne,
.freshworks .inner .agendaSection .mainBox .boxTwo {
    border: 1px solid #ededed;
    padding: 15px;
}

.freshworks .inner .agendaSection .mainBox .boxOne {
    flex: 0 1 20%;
}

.freshworks .inner .agendaSection .mainBox .boxTwo {
    flex: 0 1 80%;
}

.freshworks .inner .agendaSection .mainBox .table-responsive {
    margin: 0px;
    border: 0px;
}

.freshworks .inner .agendaSection .mainBox table {
    margin-bottom: 0px;
}

.freshworks .inner .agendaSection .mainBox table .table-dark {
    background: #000000;
}

.freshworks .inner .agendaSection .mainBox table th {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
}

.freshworks .inner .agendaSection .mainBox table td {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.freshworks .inner .agendaSection .mainBox table td b {
    font-weight: 900;
}

.CustomerSpotlight li {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #212529 !important;
    margin-left: 20px;
}
@media (max-width: 767px) {
    .CustomerSpotlight li {
        font-size: 12px !important;
        margin-left: 10px;
    }
}
.freshworks .inner .speakersSection {
    background: #000000;
}

.freshworks .inner .speakersSection .speakerBox {
    background: #ffffff;
    margin-top: 30px;
}

.freshworks .inner .speakersSection .speakerBox img, .infosys .inner .speakersSection .speakerBox img {
    height: 350px;
    width: 100%;
    object-fit: cover;
    object-position: top;
}

.freshworks .inner .speakersSection .speakerBox .textBox {
    padding: 25px;
}

.freshworks .inner .speakersSection .speakerBox .textBox h3 {
    font-size: 20px;
    font-weight: 700;
}

.freshworks .inner .speakersSection .speakerBox .textBox h4 {
    font-size: 16px;
    font-weight: 600;
}

.freshworks .inner .speakersSection .speakerBox .textBox h5 {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 400;
}

.freshworks .poweredSection {
    padding: 40px 0px;
}

.freshworks .poweredSection .poweredBox h3 {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}

.freshworks .poweredSection .poweredBox img {
    width: 150px;
}

.freshworks .poweredSection .poweredBox a {
    display: block;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.freshworks .copyrightSection {
    background: #000000;
    color: #ffffff;
    padding: 40px 0px;
    font-family: 'Roboto', sans-serif;
}

.freshworks .copyrightSection a,
.freshworks .copyrightSection p {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
}

.freshSpeakerModal {
    max-width: 600px;
}

.freshSpeakerModal .modal-content {
    border: none;
    border-radius: 0px;
}

.freshSpeakerModal .popupBox button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: 0;
    background: #ffffff;
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    color: #000000;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    outline: none;
}

.freshSpeakerModal .popupBox .boxHead {
    margin-bottom: 20px;
}

.freshSpeakerModal .popupBox .boxOne {
    display: flex;
    align-items: center;
    padding: 0px 50px;
}

.freshSpeakerModal .popupBox .boxOne img {
    width: 200px;
    margin-right: 40px;
}

.freshSpeakerModal .popupBox .boxOne .textBox h3 {
    font-size: 20px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    color: #000000;
}

.freshSpeakerModal .popupBox .boxOne .textBox h4 {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #000000;
}

.freshSpeakerModal .popupBox .boxOne .textBox h5 {
    font-size: 17px;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    color: #000000;
    font-weight: 400;
}

.freshSpeakerModal .popupBox .boxTwo {
    background: #f5f5f5;
    padding: 30px 50px;
    border-bottom: 10px solid #000000;
}

.freshSpeakerModal .popupBox .boxTwo p {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

@media only screen and (max-width: 767px) {
    .freshworks .inner .keySection .textBox h1 {
        font-size: 40px;
        margin-bottom: 20px;
    }
    .freshworks .inner .keySection .textBox p {
        margin-bottom: 20px;
    }
    .freshworks .inner .agendaSection .mainBox {
        flex-direction: column;
    }
    .freshworks .inner .speakersSection .speakerBox img,
    .infosys .inner .speakersSection .speakerBox img 
    {
        height: 100%;
    }
    .freshworks .copyrightSection p {
        margin-bottom: 20px;
    }
    .freshSpeakerModal .popupBox .boxOne {
        padding: 0px 20px 20px 20px;
        flex-direction: column;
        align-items: flex-start;
    }
    .freshSpeakerModal .popupBox .boxOne img {
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .freshSpeakerModal .popupBox .boxTwo {
        padding: 20px;
    }
}

/* Infosys CSS */

.infosys .inner h1,
.infosys .inner h2,
.infosys .inner h3,
.infosys .inner h4,
.infosys .inner h5,
.infosys .inner h6 {
    font-family: 'Roboto', sans-serif;
    color: #000000;
}

.infosys .navbar .fresh-nav .navbar-nav .nav-item .nav-link {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 16px;
}

.infosys .inner .sliderDiv {
    position: relative;
    margin-top: 78px;
}

.infosys .inner .sliderDiv .sliderText {
    position: absolute;
    top: 35%;
    left: 5%;
    transform: translateY(-35%);
    background: rgba(255, 255, 255, 0.94);
    padding: 40px;
    border-radius: 30px 0px;
    max-width: 750px;
}

.infosys .inner .sliderDiv .sliderText h2 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 10px;
}

.infosys .inner .sliderDiv .sliderText h3 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 20px;
}

.infosys .inner .sliderDiv .sliderText h5 {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 0px;
}

.infosys .inner ul {
    margin: 0px;
    padding: 0;
}

.infosys .inner p,
.infosys .inner li {
    font-family: 'Roboto', sans-serif;
    color: #000000;
    font-size: 18px;
    font-weight: 400;
}

.infosys .inner .sectionTitle {
    font-family: 'Roboto', sans-serif;
    color: #000000;
}

.infosys .inner .aboutSection {
    background: #ffffff;
}

.infosys .inner .aboutSection .imgBox {
    border-radius: 10px;
    margin-right: 20px;
    overflow: hidden;
}

.infosys .inner .aboutSection .textBox h1 {
    font-size: 60px;
    font-weight: 900;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.infosys .inner .aboutSection .textBox h2 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 15px;
}

.infosys .inner .aboutSection .textBox {
    text-align: center;
}

.infosys .inner .aboutSection .textBox p {
    font-size: 20px;
    margin-bottom: 22px;
}

.infosys .btnInfo {
    font-family: 'Roboto', sans-serif;
    background: #3781c2;
    padding: 10px 30px;
    border: 0;
    border-radius: 6px;
    font-weight: 500;
}

.infosys .btnInfo:hover {
    background: #000000;
}

.infosys .inner .whySection {
    background: #3781c2;
}

.infosys .inner .whySection .whyBox {
    background: #ffffff;
    padding: 30px;
    margin-top: 30px;
    border-radius: 10px;
    transition: all .3s ease-in-out;
}

.infosys .inner .whySection .whyBox:hover {
    background: #000000;
}

.infosys .inner .whySection .whyBox h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
}

.infosys .inner .whySection .whyBox:hover h3,
.infosys .inner .whySection .whyBox:hover p {
    color: #ffffff;
}

.infosys .inner .keySection {
    background: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.infosys .inner .keySection .textBox h1 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 40px;
}

.infosys .inner .keySection .textBox ul {
    margin-top: 20px;
    padding-left: 18px;
}

.infosys .inner .keySection .textBox ul li {
    margin-bottom: 15px;
}

.infosys .inner .keySection .textBox ul li:last-child {
    margin-bottom: 0px;
}

.infosys .inner .keySection .imgBox {
    border-radius: 10px;
    overflow: hidden;
}

.infosys .inner .agendaSection {
    background: #f5f5f5;
}

.infosys .inner .agendaSection .mainBox {
    background: #ffffff;
}

.infosys .inner .agendaSection .mainBox .table-responsive {
    margin: 0px;
    border: 0px;
}

.infosys .inner .agendaSection .mainBox table {
    margin-bottom: 0px;
}

.infosys .inner .agendaSection .mainBox table .table-dark {
    background: #000000;
}

.infosys .inner .agendaSection .mainBox table th {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
}

.infosys .inner .agendaSection .mainBox table td {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.infosys .inner .speakersSection {
    background: #3781c2;
}

.infosys .inner .speakersSection .speakerBox {
    background: #ffffff;
    margin-top: 30px;
}

.infosys .inner .speakersSection .speakerBox .textBox {
    padding: 25px;
}

.infosys .inner .speakersSection .speakerBox .textBox h3 {
    font-size: 20px;
    font-weight: 700;
}

.infosys .inner .speakersSection .speakerBox .textBox h4 {
    font-size: 16px;
    font-weight: 600;
}

.infosys .inner .speakersSection .speakerBox .textBox h5 {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 400;
}

.infosys .poweredSection {
    padding: 40px 0px;
}

.infosys .poweredSection .poweredBox {
    text-align: center;
}

.infosys .poweredSection .poweredBox h3 {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
}

.infosys .poweredSection .poweredBox img {
    width: 150px;
}

.infosys .poweredSection .poweredBox a {
    display: block;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.infosys .copyrightSection {
    background: #000000;
    color: #ffffff;
    padding: 40px 0px;
    font-family: 'Roboto', sans-serif;
}

.infosys .copyrightSection a,
.infosys .copyrightSection p {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
}

@media only screen and (max-width: 767px) {
    .infosys .inner .sliderDiv {
        margin-top: 44px;
    }
    .infosys .inner .sliderDiv .sliderText {
        position: relative;
        padding: 25px 30px;
        max-width: none;
        left: 0;
        top: 0;
        transform: none;
        background: #f5f5f5;
        border-radius: 0px 0px 20px 20px;
    }
    .infosys .inner .sliderDiv .sliderText h2 {
        font-size: 1.75rem;
    }
    .infosys .inner .sliderDiv .sliderText h3 {
        font-size: 1.5rem;
        margin-bottom: 15px;
    }
    .infosys .inner .sliderDiv .sliderText h5 {
        font-size: 1.2rem;
    }
    .infosys .inner .sectionTitle {
        font-size: 34px;
    }
    .infosys .inner .aboutSection .imgBox {
        margin-right: 0px;
        margin-bottom: 30px;
    }
    .infosys .inner .aboutSection .textBox h1 {
        font-size: 34px;
    }
    .infosys .inner .aboutSection .textBox h2 {
        font-size: 20px;
    }
    .infosys .inner .keySection .textBox h1 {
        font-size: 26px;
        margin-bottom: 20px;
    }
    .infosys .copyrightSection p {
        margin-bottom: 20px;
    }
}
