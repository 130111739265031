@import url('https://fonts.googleapis.com/css2?family=KoHo:wght@200;300;400;500;600;700&display=swap');

body {
    font-family: 'KoHo', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
.navbar,
.brand,
.btn,
a,
button,
input,
select,
textarea {
    font-family: 'KoHo', sans-serif;
}

.navbar .women-nav .navbar-nav .nav-item .nav-link {
    font-family: 'KoHo', sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.sliderDiv {
    margin-top: 75px;
}

@media (max-width: 767px) {
    .sliderDiv {
        margin-top: 45px;
    }
}

.sectionPadding {
    padding: 80px 0px;
    position: relative;
}

@media (max-width: 767px) {
    .sectionPadding {
        padding: 50px 0px;
    }
}

.sectionPadding [class*=col-] {
    z-index: 9;
}

.sectionTitle {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase;
}

.btnNew {
    background-image: linear-gradient(90deg, #e0578a 0%, #f7b700 100%);
    border: 0;
    padding: 10px 35px;
    border-radius: 0;
    font-weight: 700;
}

.shapeOne {
    position: absolute;
    top: 30%;
    right: 0;
    z-index: 1;
}

.shapeTwo {
    position: absolute;
    top: -20%;
    left: 0;
    z-index: 1;
}

.shapeThree {
    position: absolute;
    top: -15%;
    right: 0;
    z-index: 1;
}

@media (max-width: 767px) {
    .shapeOne img {
        width: 200px;
    }

    .shapeTwo {
        left: -20%;
    }

    .shapeTwo img {
        width: 200px;
    }

    .shapeThree {
        top: -9%;
    }

    .shapeThree img {
        width: 200px;
    }
}

.possibilitiSec {
    background: url(../images/new/home-bg1.jpg) #e6dcff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

.possibilitiSec .container-fluid {
    padding: 0px 275px;
}

@media only screen and (max-width: 1700px) {
    .possibilitiSec .container-fluid {
        padding: 0px 75px;
    }
}

@media (max-width: 767px) {
    .possibilitiSec .container-fluid {
        padding: 0px 15px;
    }
}

.objectivSec {
    background: #4f0366;
}

.objectivSec .innerBox {
    display: flex;
    align-items: center;
    padding: 30px 40px;
    background: #dcafff;
}

.objectivSec .innerBox h3 {
    color: #a100ff;
    font-size: 60px;
    font-weight: 700;
    margin-right: 25px;
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .objectivSec .innerBox {
        padding: 20px;
        margin-top: 10px;
    }

    .objectivSec .innerBox h3 {
        font-size: 50px;
        margin-right: 20px;
    }
}

.diplomatSec {
    background-image: linear-gradient(90deg, #e0578a 0%, #f7b700 100%);
}

.diplomatSec .container-fluid {
    padding: 0px 180px;
}

.diplomatSec h1 {
    margin-bottom: 20px;
}

.diplomatSec p {
    text-align: center;
    margin-bottom: 40px;
}

.diplomatSec .innerBox {
    position: relative;
    background: #ffffff;
    margin-top: 120px;
}

.diplomatSec .innerBox img {
    position: absolute;
    bottom: 0px;
    left: -5px;
}

.diplomatSec .innerBox div {
    padding: 15px 15px 15px 160px;
}

.diplomatSec .innerBox div h3 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}

.diplomatSec .innerBox div p {
    text-align: left;
    margin: 0;
}

@media only screen and (max-width: 1700px) {
    .diplomatSec .container-fluid {
        padding: 0px 90px;
    }
}

@media (max-width: 767px) {
    .diplomatSec .container-fluid {
        padding: 0px 15px;
    }

    .diplomatSec p {
        margin-bottom: 0;
    }
}

.steplightSec {
    background: url(../images/new/home-bg2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

@media only screen and (max-width: 1700px) {
    .steplightSec {
        background-position: 15%;
    }
}

.steplightSec .innerBox {
    text-align: center;
    margin-top: 50px;
    padding: 0px 50px;
}

.steplightSec .innerBox.Padd100 {
    padding: 0px 100px;
}

@media (max-width: 767px) {
    .steplightSec {
        background-position: bottom;
    }

    .steplightSec .innerBox,
    .steplightSec .innerBox.Padd100 {
        padding: 0px;
    }
}

.steplightSec .innerBox .imgBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4f0366;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    margin: 0 auto;
}

.steplightSec .innerBox p {
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 10px;
}

.steplightSecTwo {
    background: #dcafff;
    border-bottom: 50px solid #a100ff;
}

.steplightSecTwo .innerBox {
    padding: 30px 40px;
    background: #f8efff;
    min-height: 220px;
}

.steplightSecTwo .innerBox h3 {
    font-size: 50px;
    line-height: 50px;
    font-weight: 700;
}

@media (max-width: 767px) {
    .steplightSecTwo {
        border-bottom: 30px solid #a100ff;
    }

    .steplightSecTwo .innerBox {
        padding: 30px;
        margin-top: 20px;
        min-height: 100%;
    }
}

.speakersSec {
    background-image: linear-gradient(90deg, #e0578a 0%, #f7b700 100%);
}

.speakersSec .speakerBox {
    background: #e6dcff;
    margin-top: 30px;
}

.speakersSec .speakerBox .textBox {
    padding: 30px;
    min-height: 160px;
}

.speakersSec .speakerBox .textBox h3 {
    font-size: 20px;
    font-weight: 700;
}

.speakersSec .speakerBox .textBox h4 {
    font-size: 16px;
    font-weight: 600;
}

.speakersSec .speakerBox .textBox h5 {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 400;
}

.speakersSec.pastSpeakers {
    background: url(../images/new/home-bg3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.speakersSec.pastSpeakers .speakerBox {
    background: #f7b700;
}

.speakersSec.pastSpeakers .speakerBox .textBox {
    padding: 30px;
    min-height: 180px;
}

@media (max-width: 767px) {

    .speakersSec .speakerBox .textBox,
    .speakersSec.pastSpeakers .speakerBox .textBox {
        min-height: 100%;
    }
}

.speakerModal {
    max-width: 600px;
}

.speakerModal .modal-content {
    border: none;
    border-radius: 0px;
}

.speakerModal .popupBox button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: 0;
    background: #4f0366;
    font-size: 25px;
    line-height: 25px;
    color: #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    outline: none;
}

.speakerModal .popupBox .boxHead {
    margin-bottom: 20px;
}

.speakerModal .popupBox .boxOne {
    display: flex;
    align-items: center;
    padding: 0px 50px;
    border-bottom: 10px solid;
    border-image-slice: 1;
    border-width: 15px;
    border-image-source: linear-gradient(to left, #f7b700, #e0578a);
}

.speakerModal .popupBox .boxOne img {
    width: 200px;
    margin-right: 40px;
}

.speakerModal .popupBox .boxOne .textBox h3 {
    font-size: 20px;
    font-weight: 700;
}

.speakerModal .popupBox .boxOne .textBox h4 {
    font-size: 18px;
    color: #c255aa;
}

.speakerModal .popupBox .boxOne .textBox h5 {
    font-size: 17px;
    margin-bottom: 0;
}

.speakerModal .popupBox .boxTwo {
    background: #f4e7ff;
    padding: 30px 50px;
    border-bottom: 10px solid;
    border-image-slice: 1;
    border-width: 15px;
    border-image-source: linear-gradient(to left, #f7b700, #e0578a);
}

@media (max-width: 767px) {
    .speakerModal .popupBox .boxOne {
        padding: 0px 20px 20px 20px;
        flex-direction: column;
        align-items: flex-start;
    }

    .speakerModal .popupBox .boxOne img {
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .speakerModal .popupBox .boxTwo {
        padding: 20px;
    }
}

.agendaSec {
    background: url(../images/new/home-bg4.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

.agendaSec .agendaBox:nth-child(even) {
    background: rgba(255, 255, 255, .15);
}

.agendaSec .agendaBox {
    padding: 20px;
    text-align: center;
}

.agendaSec .agendaBox h3 {
    font-size: 20px;
    font-weight: 600;
    color: #f7b700;
    margin-bottom: 0;
    text-transform: uppercase;
}

.agendaSec .agendaBox p {
    color: #ffffff;
    margin-top: 5px;
    margin-bottom: 0;
}

footer {
    padding: 80px 0px 0px 0px;
}

footer h3 {
    text-transform: uppercase;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 700;
}

footer .sponsorsBox {
    padding: 50px 0px;
    margin-top: 60px;
    border-top: 8px solid #dcafff;
    text-align: center;
}

footer .sponsorsBox h3 {
    margin-bottom: 30px;
}

footer .copyrightSec {
    background-image: linear-gradient(90deg, #e0578a 0%, #f7b700 100%);
    padding: 80px 0px;
}

footer .copyrightSec p {
    color: #ffffff;
    margin-bottom: 0;
}

footer .copyrightSec a {
    color: #ffffff;
}

@media (max-width: 767px) {
    footer {
        padding-top: 50px;
    }

    footer h3 {
        margin-bottom: 20px;
    }

    footer .poweredBox {
        text-align: center;
    }

    footer .partnersBox {
        margin-top: 40px;
        text-align: center;
    }

    footer .partnersBox h3 {
        margin-bottom: 0px;
    }

    footer .partnersBox img {
        margin-top: 20px;
    }

    footer .copyrightSec {
        padding: 30px 0px 10px;
    }

    footer .copyrightSec p {
        margin-bottom: 20px;
    }
}