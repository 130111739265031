.styleDiscuss {
    font-size: 1.5em;
    border: 1px solid rgb(222, 222, 222);
    height: 100%;
    padding: 20px 10px 0px;
    margin-top: 20px;
    margin-bottom: 0px;
    text-align: center;
    color: rgb(245, 245, 245);
    font-weight: 500;
    padding-top: 30px;
    padding-bottom: 30px;
}
.styleDiscuss:hover {
    background-color: #fff;
    color: #0995ff;
    font-weight: 700;
}
.styleChallenge {
    font-size: 1.5em;
    border: 1px solid #000;
    height: 100%;
    padding: 20px 10px 0px;
    margin-top: 20px;
    margin-bottom: 0px;
    text-align: center;
    color: #000;
    font-weight: 500;
    padding-top: 30px;
    padding-bottom: 30px;
}

.styleChallenge:hover {
    background-color: #0995ff;
    color: #fff;
    font-weight: 700;
    border: 0px solid #dedede;
}
.agendaCss {
    font-family: 'GraphicRegular';
    line-height: 38px;
    letter-spacing: 1px;
    font-weight: 400;
    text-align: justify;
    color: #515151;
}
