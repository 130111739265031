.youtubecss {
    width: 100%;
    margin-top: 70px;
    min-height: 555px;
}

@media screen and (min-height: 900px) {
    .youtubecss {
        min-height: 850px;
    }
}